import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import HomePage from 'pages/Home'

import SystemRoute from './system'
import ActivityRoute from './activity'
import ReadingRoute from './reading'
import PlatFormRoute from './platform'
import CmsRoute from './cms'
import SesameRoute from './sesame'
import StoreRoute from './store'
import UserCenterRoute from './usercenter'

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/cicp" component={HomePage} />
      <Route path="/cicp/system" component={SystemRoute} />
      {
        ActivityRoute && <Route path="/cicp/activity" component={ActivityRoute} />
      }
      {
        ReadingRoute && <Route path="/cicp/reading" component={ReadingRoute} />
      }
      {
        PlatFormRoute && <Route path="/cicp/platform" component={PlatFormRoute} />
      }
      {
        CmsRoute && <Route path="/cicp/cms" component={CmsRoute} />
      }
      {
        UserCenterRoute && <Route path="/cicp/usercenter" component={UserCenterRoute} />
      }
      {
        SesameRoute && <Route path="/cicp/sesame" component={SesameRoute} />
      }
      {
        StoreRoute && <Route path="/cicp/store:id?" component={StoreRoute} />
      }
      {
        StoreRoute ? <Redirect to="/cicp/store/goods/home" /> : <Redirect to="/cicp" />
      }
    </Switch>
  )
}

export default AppRouter
