const isWX = /MicroMessenger/i.test(navigator.userAgent)

export const KEFU_WECODE = 'https://cicp-static.oss-cn-beijing.aliyuncs.com/platform/wechat_code-linshikefu.png'

export const TOKEN_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_CODE_LOGIN_TOKEN' : 'DEV_STORE_CODE_LOGIN_TOKEN'

export const USER_INFO_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_USER_INFO_TOKEN' : 'DEV_STORE_USER_INFO_TOKEN'

export const CART_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_CART_KEY' : 'DEV_STORE_CART_KEY'
export const SCROLL_TOP = process.env.NODE_ENV === 'production' ? 'PRO_STORE_SCROLL_TOP' : 'DEV_STORE_SCROLL_TOP'
export const DATA_LENGTH = process.env.NODE_ENV === 'production' ? 'PRO_STORE_DATA_LENGTH' : 'DEV_STORE_DATA_LENGTH'

export const ADDR_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_ADDR_KEY' : 'DEV_STORE_ADDR_KEY'
export const DRAW_ADDR_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_DRAW_ADDR_KEY' : 'DEV_STORE_DRAW_ADDR_KEY'
export const DRAW_TEM_STATE = process.env.NODE_ENV === 'production' ? 'PRO_STORE_DRAW_TEM_STATE' : 'DEV_STORE_DRAW_TEM_STATE'

export const SELECT_CART_KEY = process.env.NODE_ENV === 'production' ? 'PRO_STORE_SELECT_CART_KEY' : 'DEV_STORE_SELECT_CART_KEY'
export const SHOW_ACTIVITY_DES = process.env.NODE_ENV === 'production' ? 'PRO__STORE_SHOW_ACTIVITY_DES' : 'DEV_STORE_SHOW_ACTIVITY_DES'
export const SHOW_ACTIVITY_NUM = process.env.NODE_ENV === 'production' ? 'PRO__STORE_SHOW_ACTIVITY_NUM' : 'DEV_STORE_SHOW_ACTIVITY_NUM'
export const SHOW_ACTIVITY_TIME = process.env.NODE_ENV === 'production' ? 'PRO__STORE_SHOW_ACTIVITY_TIME' : 'DEV_STORE_SHOW_ACTIVITY_TIME'

export const KEFU_MSG = {
  msg: isWX ? '长按识别二维码' : '保存图片，微信扫一扫',
  desc: isWX ? '添加客服微信，进行咨询' : '添加客服微信，进行咨询',
}

export const TAL_ID_KEY = '__cicp_store_login_tal_id'
export const PREV_PAGE_URL = '__cicp_store_prev_page_url'
export const SPREAD_ID_KEY = '__cicp_store_spread_id'
export const SPREAD_ID = 'channel_ad_id'
export const ABC_BANK_ID_KEY = '__cicp_store_abc_bank_open_id'
