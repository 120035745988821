import { handleActions } from 'redux-actions'
import { createApiReducers } from 'reducers/utils'
import actionTypes from 'consts/reading/course/free'

const { READING_COURSE_FREE_GET_FREECOURSE } = actionTypes

const initialState = {
  requestings: {},
  errors: {},
  freecourseData: null,
}

let reducers = {
  ...createApiReducers([
    READING_COURSE_FREE_GET_FREECOURSE,
  ]),
  [READING_COURSE_FREE_GET_FREECOURSE](state, action) {
    return {
      ...state,
      freecourseData: action.payload.data,
      requestings: {
        ...state.requestings,
        [READING_COURSE_FREE_GET_FREECOURSE]: false,
      },
      errors: {
        ...state.errors,
        [READING_COURSE_FREE_GET_FREECOURSE]: null,
      },
    }
  },
}
export default handleActions(reducers, initialState)
