import { createApiActionTypes } from 'actions'

export const USER_READED_BOOK_NUM = 'user_readed_book_num'

export const USER_READING_BOOK_NAME = 'user_reading_book_name'

export default createApiActionTypes({
  POST_WX_SIGNATURE: 'pages/wx/POST_WX_SIGNATURE',
  POST_WX_SIGNATURE_SESAME: 'pages/wx/POST_WX_SIGNATURE_SESAME',
  POST_WX_SIGNATURE_MELON: 'pages/wx/POST_WX_SIGNATURE_MELON',
  POST_WX_SIGNATURE_PLATFORM: 'pages/wx/POST_WX_SIGNATURE_PLATFORM',
})
