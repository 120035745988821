import WpkReporter from 'wpk-reporter'
import { isAbcBank } from 'common/service/util'
import WindowProxy from './initWindowProxy'
import Bombay from './initBombaySdk'
import ChannelLogin from './initChannelLogin'
import Zc from './initZc'
import AbcBankChannel from './initAbcBankChannel'
import mobiWhiteMp3 from './mobi-white.mp3'

// 增加全局对象
const _windowProxy = new WindowProxy()

// 岳鹰打点
if (process.env.NODE_ENV === 'production' && window.location.hostname === 'bookh5.xueersibook.com') {
  const __wpk = new WpkReporter({
    bid: 'fd9z1sf6-gmfpluvz',
    spa: true,
    plugins: [],
  })
  __wpk.installAll()
}

// 第三方登录处理
const _channelLogin = new ChannelLogin();

if (window.location.pathname.indexOf('/cicp/store') === 0) {
  // 统计分析
  const _bombay = new Bombay();
  // 接入客服
  Zc.setUserInfo()
}

if (isAbcBank()) {
  const _initAbcBankChannel = new AbcBankChannel();
}

function createAudioElement() {
  const audio = new Audio(mobiWhiteMp3);
  audio.muted = true;
  audio.autoplay = true;
  window.globalAudio = audio;

  document.body.addEventListener('mousedown', () => {
    if (window.globalAudio.muted) {
      window.globalAudio.muted = false;
    }
  });

  document.body.addEventListener('touchstart', () => {
    if (window.globalAudio.muted) {
      window.globalAudio.muted = false;
    }
  });
}
createAudioElement();
