import { postApi, fetchApi } from 'api/store/http'

/**
 * 集团用户中心登录
 * @returns {*}
 */
export const apiCodeLogin = data => {
  return postApi('/webapi/cicp/store/v1/auth/code-login', data)
}

/**
 * 集团用户中心登录自营系统
 * @returns {*}
 */
export const apiZyCodeLogin = (data) => {
  return postApi('/ziying/login/v1/auth/code-login', data)
}

/**
 * 获取登录信息
 * @returns {*}
 */
export const apiAuthUser = data => {
  return postApi('/webapi/cicp/store/v1/auth/user', data)
}

/**
 * 更新用户信息
 * @returns {*}
 */
export const apiAuthSave = data => {
  return postApi('/webapi/cicp/store/v1/auth/save', data)
}

/**
 * 退出
 * @returns {*}
 */
export const apiAuthQuit = data => {
  return postApi('/ziying/login/v1/auth/quit', data)
}

// 获取注销原因
export const apiGetDestroyReason = () => {
  return fetchApi('/webapi/cicp/store/v1/auth/get-destroy-reason')
}

// 用户注销
export const apiDestroyAccount = (data) => {
  return postApi('/webapi/cicp/store/v1/auth/destroy-account', data)
}

/**
 * 获取个人中心订单分类及订单数
 * @returns {*}
 */
export const apiGetOrderCount = data => {
  if (window.xesChuBan) {
    data = {
      ...data,
    }
    data.is_all = 1
  }
  return postApi('/webapi/cicp/store/v1/shop-order/order-count', data)
}

export const apiGetAbcbankCodeData = data => {
  return postApi('/webapi/cicp/store/v1/auth/abcbank-codeurl', data)
}

export const apiGetAbcbankToken = data => {
  return postApi('/webapi/cicp/store/v1/auth/abcbank-token', data)
}
