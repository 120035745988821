export const ACTION_TYPE = {
  // 支付验签数据
  PAGE_PLATFORM_BUY_ORDER: 'pages/course/update_course_info',
}

export const TOKEN_KEY = process.env.NODE_ENV === 'production' ? 'PRODH5PLATTOKEN' : 'DEVH5PLATTOKEN'

// 学而思课堂公众号二维码
export const WECHAT_CODE_IMG = 'https://cdn.xueersibook.com/theworld/images/xueersibook_admin.png'

// 学而思书城公众号二维码 //TODO
export const WECHAT_CODE_XESSC = 'https://cicp-static.oss-cn-beijing.aliyuncs.com/platform/wechat_code-shucheng.png'

// https://cdn.xueersibook.com/lib/fullpage.js
// https://cdn.xueersibook.com/theworld/images/xueersibook_admin.png
//
