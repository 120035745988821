import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

const Index = lazy(() => import('pages/store/user/Index'))
const Addrset = lazy(() => import('pages/store/user/AddrSet'))
const AddrList = lazy(() => import('pages/store/user/AddrList'))
const Order = lazy(() => import('pages/store/user/Order'))
const UserInfo = lazy(() => import('pages/store/user/UserInfo'))
const UserSetting = lazy(() => import('pages/store/user/Setting'))
const CancelAccount = lazy(() => import('pages/store/user/Setting/CancelAccount'))
const CancelAccountChoose = lazy(() => import('pages/store/user/Setting/CancelAccountChoose'))
const CancelAccountResult = lazy(() => import('pages/store/user/Setting/CancelAccountResult'))
const Login = lazy(() => import('pages/store/user/Login'))
const OrderType = lazy(() => import('pages/store/user/OrderType'))

const SpellGroupList = lazy(() => import('pages/store/user/SpellGroupList'))
const SpellGroupDetails = lazy(() => import('pages/store/user/SpellGroupDetails'))
const SpellGroupPlayDesc = lazy(() => import('pages/store/user/SpellGroupPlayDesc'))
const SpellGroupOrder = lazy(() => import('pages/store/user/SpellGroupOrder'))
const ReturnGoodsList = lazy(() => import('pages/store/user/ReturnGoodsList'))
const CouponList = lazy(() => import('pages/store/user/CouponList'))
const Equity = lazy(() => import('pages/store/user/Equity'))
const LuckyDraw = lazy(() => import('pages/store/user/LuckyDraw'))
const UserDrawRecord = lazy(() => import('pages/store/user/UserDrawRecord'))
const EquityExchange = lazy(() => import('pages/store/user/EquityExchange'))
const EquityExchangeAuto = lazy(() => import('pages/store/user/EquityExchangeAuto'))
const EquityDesc = lazy(() => import('pages/store/user/EquityDesc'))
const QuestionList = lazy(() => import('pages/store/user/question'))
const QuestionDesc = lazy(() => import('pages/store/user/questionDesc'))

const NotFound = lazy(() => import('pages/store/NotFound'))
const About = lazy(() => import('pages/store/user/about'))

const ActivityRoute = ({ match }) => {
  const rootUrl = match.url
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={`${rootUrl}/index/:showHz?`} component={Index} />
        <Route exact path={`${rootUrl}/orderType/:orderSn/:productUnionid`} component={OrderType} />
        <Route exact path={`${rootUrl}/addrSet/:type?/:add?`} component={Addrset} />
        <Route exact path={`${rootUrl}/addrList/:type?`} component={AddrList} />
        <Route exact path={`${rootUrl}/order/:orderNo?`} component={Order} />
        <Route exact path={`${rootUrl}/userInfo`} component={UserInfo} />
        <Route exact path={`${rootUrl}/setting`} component={UserSetting} />
        <Route exact path={`${rootUrl}/about`} component={About} />
        <Route exact path={`${rootUrl}/setting/cancelAccount`} component={CancelAccount} />
        <Route exact path={`${rootUrl}/setting/cancelAccountChoose`} component={CancelAccountChoose} />
        <Route exact path={`${rootUrl}/setting/cancelAccountResult/:type`} component={CancelAccountResult} />
        <Route exact path={`${rootUrl}/login`} component={Login} />
        <Route exact path={`${rootUrl}/spellGroupList`} component={SpellGroupList} />
        <Route exact path={`${rootUrl}/spellGroupDetails/:orderSn`} component={SpellGroupDetails} />
        <Route exact path={`${rootUrl}/spellGroupPlayDesc`} component={SpellGroupPlayDesc} />
        <Route exact path={`${rootUrl}/spellGroupOrder/:orderSn`} component={SpellGroupOrder} />
        <Route exact path={`${rootUrl}/returnGoodsList`} component={ReturnGoodsList} />
        <Route exact path={`${rootUrl}/couponList`} component={CouponList} />
        <Route exact path={`${rootUrl}/equity`} component={Equity} />
        <Route exact path={`${rootUrl}/equityExchange`} component={EquityExchange} />
        <Route exact path={`${rootUrl}/equityExchangeAuto/:actId`} component={EquityExchangeAuto} />
        <Route exact path={`${rootUrl}/equityDesc`} component={EquityDesc} />
        <Route exact path={`${rootUrl}/questionList/:tabNo?`} component={QuestionList} />
        <Route exact path={`${rootUrl}/questionDesc/:url`} component={QuestionDesc} />
        <Route exact path={`${rootUrl}/luckyDraw/:activityId`} component={LuckyDraw} />
        <Route exact path={`${rootUrl}/userDrawRecord/:activityId`} component={UserDrawRecord} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Suspense>
  )
}
export default ActivityRoute
