import Bombay from 'cicp-bombayjs'
import { getParams } from 'common/service/util'
import {
  TAL_ID_KEY,
  PREV_PAGE_URL,
  SPREAD_ID_KEY,
  SPREAD_ID,
} from 'consts/store/user'

export default class cicpBombay {
  constructor() {
    this.init()
  }

  init = () => {
    window.addEventListener('popstate', () => {
      window.ud && window.ud('hidePanel')
    })
    const _id = getParams()[SPREAD_ID]
    if (_id) {
      window.sessionStorage.setItem(SPREAD_ID_KEY, _id)
      window.sessionStorage.setItem(PREV_PAGE_URL, window.location.href)
    }
    const _bbq = new Bombay({
      token: 'helloworldthisistoken',
      reportUrl: '/webapi/cicp/bi/shop/analysis/save',
      enableSPA: true,
      isPage: false,
      isError: false,
      isAjax: false,
      isRecord: false,
      isResource: false,
      isBehavior: false,
      behavior: false,
      onHackState() {
        window.ud && window.ud('hidePanel')
        window.sessionStorage.setItem(PREV_PAGE_URL, window.location.href)
      },
      // 拦截上报 true 拦截
      interceptReport(e) {
        if (window.location.href.includes('/login')) {
          return true
        }
        if (e.t === 'health') {
          // if (e.o.includes(e.page) && e.o.includes(SPREAD_ID)) return false
          return true
        }
        const prevUrl = window.sessionStorage.getItem(PREV_PAGE_URL) || '';
        return !window.location.href.includes(SPREAD_ID) && !prevUrl.includes(SPREAD_ID)
      },
      resetRequestData(e) {
        let type = 1
        if (e.t === 'health') {
          type = 3
          // 跳出不上报
          return null
        }
        if (!window.location.href.includes(SPREAD_ID)) {
          type = 2
        }
        const pageId = window.sessionStorage.getItem(SPREAD_ID_KEY)
        const params = {
          tal_id: window.localStorage.getItem(TAL_ID_KEY) || '',
          uuid: e.uid,
          visit_time: parseInt(Date.now() * 0.001, 10),
          monitor_type: type,
        }
        if (pageId) {
          params.monitor_page_id = parseInt(pageId, 10)
        }
        return params
      },
    })
  }
}
