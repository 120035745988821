import { createApiActionTypes } from 'actions'

export default createApiActionTypes({
  READING_USER_HOME_GET_USERINFO: 'reading/user/home/GET_UserInfo',
})

export const PAGE_META = {
  title: '鹦鹉爱阅读',
  description: '学而思出版中心-鹦鹉爱阅读活动',
  meta: {
    keywords: '英语，少儿教育，科普，学前，学而思',
  },
}
