import activityReducers from './activity'
import readingReducers from './reading'
import wxReducers from './wx'
import platformReducers from './platform'
import storeReducers from './store'

export default ({
  ...activityReducers,
  ...readingReducers,
  ...wxReducers,
  ...platformReducers,
  ...storeReducers,
})
