import { handleActions } from 'redux-actions'
import { createApiReducers } from 'reducers/utils'
import { ACTION_TYPE } from 'consts/platform/order'

const {
  PAGE_PLATFORM_BUY_ORDER,
} = ACTION_TYPE

const initialState = {
  buyOrdeRresData: {}, // 下单接返回数据
}

let reducers = {
  ...createApiReducers([
  ]),
  [PAGE_PLATFORM_BUY_ORDER](state, action) {
    return {
      ...state,
      buyOrdeRresData: action.payload,
    }
  },
}
export default handleActions(reducers, initialState)
