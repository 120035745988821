import React from 'react'
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind'
import styles from './style.styl'
import imagePng from './image.png'

const cx = classNames.bind(styles)

let Type = {
  wxpay: 'wxpay',
  alipay: 'alipay',
  wxpayH5: 'wxpay_h5',
}

class PayToAliPay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      alternate: false,
    }
  }

  show = () => {
    this.setState({
      show: true,
      selectIndex: 0,
    })
  }


  close =() => {
    this.handleCloseClick()
  }

  handleCloseClick = (callBack) => {
    this.setState({
      alternate: true,
    })
    this.timeOut = setTimeout(() => {
      this.setState({
        show: false,
        alternate: false,
      }, callBack)
    }, 250)
  }

  handleItemClick =(e, index) => {
    e.stopPropagation()
    this.setState({
      selectIndex: index,
    })
  }

  handleSubClick = (e) => {
    e.stopPropagation()
    const { list, list1, selectIndex } = this.state
    if (selectIndex === -1) return
    this.handleCloseClick(() => {
      const { type } = this.props
      let data = list1
      if (type === Type.ReturnGoods) {
        data = list
      }
      this.handleSelect(data[selectIndex].type)
    })
  }

  render() {
    const { show, alternate } = this.state
    if (!show) {
      return null
    }


    return (
      <div className={cx('root', alternate ? 'root-box-alternate' : 'root-box-normal')} onClick={this.close} >
        <div className={cx('body-box')} >
        <div className={cx('body', alternate ? 'delete-box-alternate' : 'delete-box-normal')} >
          <img className={cx('img')} src={imagePng} ></img>
          <label className={cx('desc')} >微信内不支持支付宝付款方式</label>
          <label className={cx('title')} >点击右上角「···」，打开其它浏览器</label>
          <label className={cx('title')} >即可进行支付哦～</label>
        </div>
        </div>
      </div>
    )
  }
}

let div = document.createElement('div');
document.body.appendChild(div);
const payToAliPay = ReactDOM.render(React.createElement(
  PayToAliPay,
), div)
export default payToAliPay
