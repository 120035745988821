// import './publicPath'
import '@babel/polyfill'
import './global'
import React from 'react'
import ReactDOM from 'react-dom'
import 'common/reset.styl'
import { isDevEnv, isTestEnv, enableVConsole } from 'common/service/util'
import Root from './containers/Root'
import store from './store/index'

// 此处自行切换是否在开发环境开启 vconsole
if (isTestEnv()) {
  enableVConsole()
}

function render(props) {
  const { container } = props;
  const rootDom = container ? container.querySelector('#root') : document.querySelector('#root')
  rootDom.style.height = '100%'
  // rootDom.className += 'theme-qk' // 轻课主题
  ReactDOM.render(<Root store={store} />, rootDom);
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('[react16] react app bootstraped');
}

export async function mount(props) {
  console.log('[react16] props from main framework', props);
  render(props);
}

export async function unmount() {
  ReactDOM.unmountComponentAtNode(document.getElementById('root'));
}
