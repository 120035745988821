import queryString from 'query-string'
import dayjs from 'dayjs'
import sha1 from 'js-sha1'
import { CHANNEL_APP_KEY_CICP, WANGXIAO_APP } from 'consts/store/channel'
import { TOKEN_KEY, USER_INFO_KEY } from 'consts/store/user'
import { Toast } from 'antd-mobile'
import paySelect from 'storeUI/PaySelect'
import payToAliPay from 'storeUI/PayToAliPay'
import { apiErrorLog } from 'api/cms/common'

export const analyzeMsg = (msg = '') => {
  const match = msg.match(/(sites.xueersibook.com)(.*?)(:\d+:\d+)/)
  const response = {
    msg,
    line: null,
    column: null,
  }
  if (!match) return response
  const params = match[0].split(':')
  if (params.length !== 3) return response
  let [path, line, column] = params
  if (path.indexOf('h5static') > -1) {
    path = path.replace('sites.xueersibook.com', 'pre-bookh5.xueersibook.com')
  } else if (path.indexOf('webstatic') > -1) {
    path = path.replace('sites.xueersibook.com', 'pre-bookadmin.xueersibook.com')
  }
  path = `http://${path}.map`
  let resolve = `http://fe-sites.xueersibook.com/sourcemap?url=${path}&l=${line}&c=${column}`
  return {
    msg,
    line,
    column,
    resolve,
  }
}

export const logReport = (msg, url, lineNo, columnNo, error) => {
  const timestamp = Date.now();
  const { performance } = window
  const domComplete = performance && performance.timing && performance.timing.domComplete
  let response = {
    htmlVersion: window.XES_STORE_VERSION,
    href: window.location.href,
    url,
    stack: '',
    time: dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss'),
    domComplete: dayjs(domComplete || 0).format('YYYY-MM-DD HH:mm:ss'),
    agent: navigator ? navigator.userAgent : '未知userAgent',
    t: window.localStorage.getItem(TOKEN_KEY),
  }

  // 如果是 http 请求中抛出的后端数据，不上报
  if (error && error.status) {
    return true
  }

  if (error !== null) {
    let u = (error.stack || '').split('\n');
    u.shift();
    response.stack = u.join('\n')
  }

  let string = msg.toLowerCase();

  let substring = 'sites.xueersibook.com';
  if (string.indexOf(substring) === -1) {
    return true
  }

  substring = 'script error';
  if (string.indexOf(substring) > -1) {
    // response.msg = 'Script Error: See Browser Console for Detail'
    // response.html = document.body.parentElement.outerHTML
    // apiErrorLog({ logger: response })
    // return false
    return true
  }

  substring = 'chunkloaderror: loading chunk';
  if (string.indexOf(substring) > -1) {
    return true
  }

  substring = 'error: loading css chunk';
  if (string.indexOf(substring) > -1) {
    return true
  }

  const channelAppKeyCicp = window.localStorage.getItem(CHANNEL_APP_KEY_CICP)
  if (channelAppKeyCicp) {
    response.channel = channelAppKeyCicp
  }

  if (!response.stack.includes('xueersibook.com') && !msg.includes('xueersibook.com')) {
    return true
  }

  if (lineNo) {
    response.msg = msg
    response.line = lineNo
    response.column = columnNo
  } else {
    let pathResolve = analyzeMsg(msg)
    response = {
      ...response,
      ...pathResolve,
    }
  }
  apiErrorLog({ logger: response })
  return false
}

export const isFirefox = () => {
  let useragent = navigator.userAgent.toLowerCase()
  return useragent.indexOf('firefox') > -1
}
export const isChrome = () => {
  let useragent = navigator.userAgent.toLowerCase()
  return useragent.indexOf('chrome') > -1
}
export const isMobile = () => {
  let useragent = navigator.userAgent.toLowerCase()
  return useragent.indexOf('mobile') > -1 || useragent.indexOf('android') > -1
}

export const isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent)
export const isAndroid = () => {
  let u = navigator.userAgent;
  let is = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  return is
}

export const isWeiXin = () => /MicroMessenger/i.test(navigator.userAgent)

export const isAbcBank = () => /Bankabc\/Portal/i.test(navigator.userAgent)

export const isDevEnv = () => {
  const { hostname } = window.location
  return (hostname.indexOf('dev') === 0
    || hostname.indexOf('local') === 0
    || hostname === 'localhost'
    || hostname.indexOf('1') === 0
  )
}

export const isIpEnv = () => {
  const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(window.location.hostname)
}

export const isTestEnv = () => {
  const { hostname } = window.location
  return hostname.indexOf('test') === 0
}

export const pushUrlLogin = function pushUrlLogin(url) {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (token) {
    this.props.history.push(url)
  } else {
    this.props.history.push(window.GET_CICP_STORE_NEW_URL(`/cicp/store/user/login?jumpurl=${url}`, false))
  }
}

export const historyPushUrlLogin = (history, url) => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (token) {
    history.push({
      pathname: url,
    })
  } else {
    window.location.href = window.GET_CICP_STORE_NEW_URL(`/cicp/store/user/login?jumpurl=${url}`, false)
  }
}

export const enableVConsole = () => {
  const times = 6
  let clickedTime = 0
  if (isIos()) {
    document.body.style.cursor = 'pointer'
  }
  function initVconsole() {
    clickedTime += 1
    if (clickedTime === 1) {
      setTimeout(() => {
        clickedTime = 0
      }, 2000);
    } else if (clickedTime >= times) {
      try {
        import('vconsole').then(VConsole => {
          try {
            const Vconsole = VConsole.default
            const vconsole = new Vconsole()
          } catch (e) {}
        })
      } catch (e) {

      }
      document.body.removeEventListener('click', initVconsole)
    }
  }
  document.body.addEventListener('click', initVconsole)
}


// 获取？后面的参数对象
export const getParams = (url = window.location.search) => queryString.parse(url)

export const stringify = (obj) => queryString.stringify(obj)

// 正则获取url后面的参数
export const getQueryString = (name) => {
  let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null;
}

// 跳转到指定课程模块
export const gotoCourse = (history, course, params, way = 'push') => {
  const search = getParams()
  const newParams = queryString.stringify({ ...search, ...params })

  // window.location.href = `${window.location.origin}/cicp/reading/course/${course}?${newParams}`
  const route = {
    pathname: `/cicp/reading/course/${course}`,
    search: `?${newParams}`,
  }
  if (way === 'replace') {
    history.replace(route)
  } else {
    history.push(route)
  }
}

// 跳转到指定页面中
export const goto = (history, url, params, way = 'push') => {
  const search = getParams()
  const newParams = queryString.stringify({ ...search, ...params })

  // window.location.href = `${url}?${newParams}`
  const route = {
    pathname: url,
    search: `?${newParams}`,
  }
  if (way === 'replace') {
    history.replace(route)
  } else {
    history.push(route)
  }
}

export const gotoStorePayWithType = (orderSn, type, toAliPay = true, isHref = false, productUnionid) => {
  let productId = productUnionid;
  if (!productId) {
    productId = window.CICP_STORE_ID
  }
  const sourceid = window.sessionStorage.getItem('STORE_SOURCE_ID')
  const ocpx = window.sessionStorage.getItem('OCPX')
  const backJumpurl = window.GET_CICP_STORE_NEW_URL(`/cicp/store/user/ordertype/${orderSn}/${productId}`, false)
  const handleUrl = (url) => {
    if (isHref) {
      window.location.href = url
    } else {
      window.location.replace(url)
    }
  }
  if (isWeiXin() && type === 'alipay') {
    if (toAliPay) {
      let url = sourceid ? `${backJumpurl}?toAliPay=true&xeswx_sourceid=${sourceid}` : `${backJumpurl}?toAliPay=true`
      handleUrl(url)
    } else {
      payToAliPay.show()
      if (window.location.href.indexOf(backJumpurl) === -1) {
        let url = sourceid ? `${backJumpurl}?toAliPay=true&xeswx_sourceid=${sourceid}` : `${backJumpurl}?toAliPay=true`
        handleUrl(url)
      } else {
        let url = sourceid ? `${backJumpurl}?toAliPay=true&xeswx_sourceid=${sourceid}` : `${backJumpurl}?toAliPay=true`
        window.history.replaceState({}, document.title, url)
      }
    }
    return
  }
  const jumpurl = window.GET_CICP_STORE_NEW_URL('/cicp/store/goods/payStatus/', false)
  console.log(type)
  switch (type) {
    case 'wxpay':
      let wxpaybackJumpurl = sourceid ? encodeURIComponent(`${backJumpurl}?xeswx_sourceid=${sourceid}`) : backJumpurl
      let reseturl = encodeURIComponent(`${window.location.origin}/cicp/usercenter/pay/?orderSn=${orderSn}&type=store&jumpurl=${jumpurl}&backJumpurl=${wxpaybackJumpurl}&productUnionid=${productId}`)
      let newurl = `${isTestEnv() ? 'http://test-plat' : 'https://plat'}.xesvip.cn/wechat/auth-pay?pay_sn=${orderSn}&back_url=${reseturl}`
      handleUrl(newurl)
      break;
    case 'alipay':
      let alinewbackJumpurl = sourceid ? encodeURIComponent(`${backJumpurl}?xeswx_sourceid=${sourceid}`) : backJumpurl
      handleUrl(`/cicp/usercenter/pay/?orderSn=${orderSn}&type=store&jumpurl=${jumpurl}&backJumpurl=${alinewbackJumpurl}&payCode=alipay&productUnionid=${productId}`)
      break;
    case 'wxpay_h5':
      let wxnewbackJumpurl = sourceid ? encodeURIComponent(`${backJumpurl}?xeswx_sourceid=${sourceid}`) : backJumpurl
      handleUrl(`/cicp/usercenter/pay/?orderSn=${orderSn}&type=store&jumpurl=${jumpurl}&backJumpurl=${wxnewbackJumpurl}&payCode=wxpay_h5&productUnionid=${productId}`)
      break;
    case 'abc_bank':
      handleUrl(`/cicp/usercenter/pay/?orderSn=${orderSn}&type=store&jumpurl=${jumpurl}&backJumpurl=${backJumpurl}&payCode=abc_bank&productUnionid=${productId}`)
      break;
    default:
      break;
  }
}

export const gotoStorePay = (orderSn, callBack, isHref = false, productUnionid) => {
  let productId = productUnionid;
  if (!productId) {
    productId = window.CICP_STORE_ID
  }
  console.log('选择支付')

  if (isAbcBank()) {
    if (callBack) {
      callBack('abc_bank')
      return
    }
    gotoStorePayWithType(orderSn, 'abc_bank', false, isHref, productId)
    return;
  }

  paySelect.show()
  paySelect.handleSelect = (type) => {
    if (callBack) {
      callBack(type)
      return
    }
    gotoStorePayWithType(orderSn, type, false, isHref, productId)
  }
}

export const gotoPlatformPayType = (paySn, orderType, goodId, regOrderSn, type) => {
  const backJumpurl = '/cicp/platform/my/order'
  switch (type) {
    case 'wxpay':
      let reseturl = encodeURIComponent(`${window.location.origin}/cicp/usercenter/pay/?paySn=${paySn}&type=platform&orderType=${orderType}&backJumpurl=${backJumpurl}&goodId=${goodId}`)
      let newurl = `${isTestEnv() ? 'http://test-plat' : 'https://plat'}.xesvip.cn/wechat/auth-pay?pay_sn=${paySn}&back_url=${reseturl}`
      window.location.replace(newurl)
      break;
    case 'alipay':
      if (isWeiXin()) {
        Toast.info('请在微信外使用支付宝')
      } else {
        window.location.replace(`/cicp/usercenter/pay/?paySn=${paySn}&type=platform&orderType=${orderType}&backJumpurl=${backJumpurl}&payCode=alipay&goodId=${goodId}&regOrderSn=${regOrderSn}`)
      }
      break;
    case 'wxpay_h5':
      window.location.replace(`/cicp/usercenter/pay/?paySn=${paySn}&type=platform&orderType=${orderType}&backJumpurl=${backJumpurl}&payCode=wxpay_h5&goodId=${goodId}&regOrderSn=${regOrderSn}`)
      break;
    default:
      break;
  }
}

export const gotoPlatformPay = (paySn, orderType, goodId, regOrderSn, callBack) => {
  console.log('选择支付')
  paySelect.show()
  paySelect.handleSelect = (type) => {
    if (callBack) {
      callBack(type)
      return
    }
    gotoPlatformPayType(paySn, orderType, goodId, regOrderSn, type)
  }
}

export const gotoPlatformPayConfirm = (callBack) => {
  paySelect.show()
  paySelect.handleSelect = (type) => {
    callBack(type)
  }
}

export const getWeiXinLoginUrl = (url = '/') => {
  const HOST = window.location.origin
  const redirectUri = encodeURIComponent(`${HOST}/feauth/loginWx`)
  const backUrl = encodeURIComponent(url);
  const appid = process.env.NODE_ENV === 'production' ? 'wx828697bb17b63783' : 'wxc3880dda8eb27051'
  // eslint-disable-next-line max-len
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${backUrl}#wechat_redirect`
}

export const numToNo = no => (no < 10 ? `0${no}` : `${no}`)

export const sleep = (millisecond) => {
  return new Promise((resolve) => {
    setTimeout(resolve, millisecond)
  })
}
export const timeFormt = (timeStamp) => {
  let date = new Date(timeStamp * 1000);
  let Y = `${date.getFullYear()}-`;
  let M = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-`;
  let D = date.getDate() < 10 ? `0${date.getDate()} ` : `${date.getDate()} `;
  let h = date.getHours() < 10 ? `0${date.getHours()}:` : `${date.getHours()}:`;
  let m = date.getMinutes() < 10 ? `0${date.getMinutes()}:` : `${date.getMinutes()}:`;
  let s = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return Y + M + D + h + m + s
}
export const timeFormt2 = (timeStamp) => {
  let date = new Date(timeStamp * 1000);
  let Y = `${date.getFullYear()}年`;
  let M = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}月`;
  let D = `${date.getDate()}日 `;
  let h = date.getHours() < 10 ? `0${date.getHours()}:` : `${date.getHours()}:`;
  let m = date.getMinutes() < 10 ? `0${date.getMinutes()}:` : `${date.getMinutes()}:`;
  let s = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return Y + M + D + h + m + s
}

// 倒计时时间转化
export const interTimeFormat = (timestamp) => {
  let resultTime = {
    day: '00',
    hour: '00',
    minute: '00',
    second: '00',
  }
  let t = timestamp
  if (t > 0) {
    let day = Math.floor(t / 86400000)
    let hour = Math.floor((t / 3600000) % 24)
    let min = Math.floor((t / 60000) % 60)
    let sec = Math.floor((t / 1000) % 60)
    day = day < 10 ? `0${day}` : day
    hour = hour < 10 ? `0${hour}` : hour
    min = min < 10 ? `0${min}` : min
    sec = sec < 10 ? `0${sec}` : sec
    resultTime.day = day
    resultTime.hour = hour
    resultTime.minute = min
    resultTime.second = sec
  }

  return resultTime
}
// 倒计时
export const countDown = (times) => {
  let timeDown = times
  let jishiTime = {
    day: '00',
    hour: '00',
    minute: '00',
    second: '00',
  }
  let timer = null

  let setTime = () => {
    let day = 0
    let hour = 0
    let minute = 0
    let second = 0
    if (timeDown > 0) {
      day = Math.floor(timeDown / (60 * 60 * 24));
      hour = Math.floor(timeDown / (60 * 60)) - (day * 24);
      minute = Math.floor(timeDown / 60) - (day * 24 * 60) - (hour * 60);
      second = Math.floor(timeDown) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60);
    }
    if (day <= 9) day = `0${day}`;
    if (hour <= 9) hour = `0${hour}`;
    if (minute <= 9) minute = `0${minute}`;
    if (second <= 9) second = `0${second}`;
    jishiTime.day = day
    jishiTime.hour = hour
    jishiTime.minute = minute
    jishiTime.second = second
  }

  setTime()

  function clearTime() {
    clearInterval(timer)
  }

  function startDown(end) {
    timer = setInterval(() => {
      setTime()
      if (timeDown >= 0) {
        timeDown -= 1
      } else {
        clearTime()
      }
      end(jishiTime)
    }, 1000)
  }

  function getjishiTime() {
    return jishiTime
  }

  return {
    startDown,
    getjishiTime,
    clearTime,
  }
}


/**
 * @description 防抖函数，装饰的对象要避免箭头函数
 * @param time 设定防抖的时长，默认 500 毫秒
 * @returns 包装后的函数
 * class A {
 *   @debounce(1000)
 *   clickEvent(e){
 *     // do something
 *   }
 * }
 */
export function debounce(time = 500) {
  return function _timeout(target, name, descriptor) {
    let timeout = null;
    let fn = descriptor.value;
    descriptor.value = function _value(...list) {
      if (timeout !== null) clearTimeout(timeout);
      timeout = setTimeout(() => fn.call(this, ...list), time);
    };
    return descriptor;
  }
}

/**
 * @description 节流函数，装饰的对象要避免箭头函数
 * @param time 设定节流的时长，默认 500 毫秒
 * @returns 包装后的函数
 * class A {
 *   @throttle(1000)
 *   clickEvent(e){
 *     // do something
 *   }
 * }
 */
export function throttle(time = 500) {
  return function _timeout(target, name, descriptor) {
    let isLocked = false;
    let fn = descriptor.value;
    descriptor.value = function _value(...list) {
      if (!isLocked) {
        fn.call(this, ...list);
        isLocked = true;
        setTimeout(() => isLocked = false, time);
      }
    }
    return descriptor;
  }
}

export const ossFormat = (url = '', w = 750) => {
  const $width = window.innerWidth || document.body.clientWidth
  let newWidth = w
  if ($width > 375) {
    newWidth = parseInt($width * w / 375, 10)
  }
  const isWebp = window.isSupportedWebp
  const hasParams = url.indexOf('?') !== -1
  if (
    url.indexOf('oss-cn') === -1
    || url.indexOf('.ico') !== -1) {
    return url
  }

  let ossParams = `x-oss-process=image/resize,w_${newWidth}/interlace,1/format,webp`
  if (!isWebp) {
    ossParams = `x-oss-process=image/resize,w_${newWidth}/interlace,1`
  }
  return `${url}${hasParams ? '&' : '?'}${ossParams}`
}

export const isWangXiaoPlatform = () => {
  const channelAppKeyCicp = window.localStorage.getItem(CHANNEL_APP_KEY_CICP)
  return channelAppKeyCicp === WANGXIAO_APP
}

export const randomString = (len = 32) => {
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < len; i += 1) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export const getUdeskCustomerConfig = () => {
  const nonce = randomString(16)
  const timestamp = Date.now()
  const uid = window.localStorage.getItem('__cicp_store_udesk_uid')
  let webToken = uid
  if (!webToken) {
    webToken = randomString(16)
    window.localStorage.setItem('__cicp_store_udesk_uid', webToken)
  }
  let signature = sha1(`nonce=${nonce}&timestamp=${timestamp}&web_token=${webToken}&ef5b239b181ef0d3f4fef7c8aade4b02`)
  signature = signature.toUpperCase()
  const config = {
    nonce,
    timestamp,
    web_token: webToken,
    signature,
  }
  let user = null
  try {
    const USER_INFO = window.localStorage.getItem(USER_INFO_KEY)
    user = JSON.parse(USER_INFO)
  } catch (e) {}
  if (user) {
    config.customer_token = user.tal_id
  }
  return config
}
