import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

const GroupMore = lazy(() => import('pages/store/group/GroupMore'))
const GroupDetails = lazy(() => import('pages/store/group/GroupDetails'))
const GroupSureOrder = lazy(() => import('pages/store/group/GroupSureOrder'))

const ActivityRoute = ({ match }) => {
  const rootUrl = match.url
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={`${rootUrl}/moreGroup`} component={(GroupMore)} />
        <Route exact path={`${rootUrl}/groupDetails`} component={(GroupDetails)} />
        <Route exact path={`${rootUrl}/groupSureOrder/:address?`} component={(GroupSureOrder)} />
      </Switch>
    </Suspense>
  )
}
export default ActivityRoute
