import { postApi } from 'api/store/http'
/**
 * 微页面 基本组件信息
 */
export const apiMicroPageInfo = (data) => {
  return postApi('/webapi/cicp/store/v1/micropage/detail', data)
}
/**
 *商品空白模板商品数据
 */
export const apiGoodsInfo = (data, cb) => {
  return postApi('/webapi/cicp/store/v1/micropage/goods', data, {}, cb)
}

/**
 *知音楼APP登录
 */
export const apiYachLogin = (data) => {
  return postApi('/webapi/cicp/store/v1/auth/yach-login', data)
}

/**
 *培优APP登录
 */
export const apiPeiyouLogin = (data) => {
  return postApi('/webapi/cicp/store/v1/auth/peiyou-login', data)
}
/**
 *获取满包邮活动状态
 */
export const apiGetFullPackageDetails = (data) => {
  return postApi('/webapi/cicp/store/v1/full-package/detail', data)
}
