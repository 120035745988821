import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

const GoodsHome = lazy(() => import('pages/store/goods/Home'))
const GoodsTypes = lazy(() => import('pages/store/goods/Types'))
const GoodsDetail = lazy(() => import('pages/store/goods/Detail'))
const GoodsTppDetail = lazy(() => import('pages/store/goods/Tpp'))
const GoodsTppResult = lazy(() => import('pages/store/goods/TppResult'))

const SureOrder = lazy(() => import('pages/store/goods/SureOrder'))
const ShopCart = lazy(() => import('pages/store/goods/ShopCart'))
const GoodMore = lazy(() => import('pages/store/goods/GoodMore'))
const MicroPage = lazy(() => import('pages/store/goods/MicroPage'))
const PayStatus = lazy(() => import('pages/store/goods/PayStatus'))
const PriceGoods = lazy(() => import('pages/store/goods/PriceGoods'))

const BackChoice = lazy(() => import('pages/store/goods/BackChoice'))
const BackDetail = lazy(() => import('pages/store/goods/BackDetail'))
const BackEMS = lazy(() => import('pages/store/goods/BackEMS'))
const BackGoods = lazy(() => import('pages/store/goods/BackGoods'))
const BackPays = lazy(() => import('pages/store/goods/BackPays'))
const Search = lazy(() => import('pages/store/goods/Search'))

const SearchResult = lazy(() => import('pages/store/goods/Search/result'))
const GoodsCoupon = lazy(() => import('pages/store/goods/Coupon'))

const NotFound = lazy(() => import('pages/store/NotFound'))


const ActivityRoute = ({ match, location }) => {
  const rootUrl = match.url
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={`${rootUrl}/home/:pageId?`} component={(GoodsHome)} />
        <Route exact path={`${rootUrl}/types/:typeId?`} component={(GoodsTypes)} />
        <Route exact path={`${rootUrl}/detail/:spuId/:cartId?`} component={(GoodsDetail)} key={location.key} />
        <Route exact path={`${rootUrl}/tpp/:spuId/:goodId`} component={(GoodsTppDetail)} />
        <Route exact path={`${rootUrl}/tppresult`} component={(GoodsTppResult)} />

        <Route exact path={`${rootUrl}/submitOrder/:pageFrom/:address?`} component={(SureOrder)} />
        <Route exact path={`${rootUrl}/shopCart`} component={(ShopCart)} />
        <Route exact path={`${rootUrl}/goodMore/:pageId/:id/:typeId?`} component={(GoodMore)} />
        <Route exact path={`${rootUrl}/microPage/:pageId`} component={(MicroPage)} />
        <Route exact path={`${rootUrl}/payStatus/:orderSn?`} component={(PayStatus)} />
        <Route exact path={`${rootUrl}/backchoice/:orderSn/:goodId?`} component={(BackChoice)} />
        <Route exact path={`${rootUrl}/backdetail/:refundSn/:productUnionid`} component={(BackDetail)} />
        <Route exact path={`${rootUrl}/backems/:refundSn/:productUnionid`} component={(BackEMS)} />
        <Route exact path={`${rootUrl}/backgoods/:type?`} component={(BackGoods)} />
        <Route exact path={`${rootUrl}/backpays`} component={(BackPays)} />
        <Route exact path={`${rootUrl}/priceGoods`} component={(PriceGoods)} />
        <Route exact path={`${rootUrl}/search`} component={(Search)} />
        <Route exact path={`${rootUrl}/search/result`} component={(SearchResult)} />
        <Route exact path={`${rootUrl}/coupon`} component={(GoodsCoupon)} />
        <Route exact path="*" component={(NotFound)}/>
      </Switch>
    </Suspense>
  )
}
export default ActivityRoute
