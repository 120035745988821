import { handleActions } from 'redux-actions'
import { createApiReducers } from 'reducers/utils'
import actionTypes from 'consts/reading/user/home'

const { READING_USER_HOME_GET_USERINFO } = actionTypes

const initialState = {
  requestings: {},
  errors: {},
  userInfo: null,
}

let reducers = {
  ...createApiReducers([
    READING_USER_HOME_GET_USERINFO,
  ]),
  [READING_USER_HOME_GET_USERINFO](state, action) {
    return {
      ...state,
      userInfo: action.payload.data,
      requestings: {
        ...state.requestings,
        [READING_USER_HOME_GET_USERINFO]: false,
      },
      errors: {
        ...state.errors,
        [READING_USER_HOME_GET_USERINFO]: null,
      },
    }
  },
}
export default handleActions(reducers, initialState)
