import { createApiActionTypes } from 'actions'

export default createApiActionTypes({
  READING_USER_LEVEL_GET_GRADEAGE: 'reading/user/level/GET_gradedAge',
  READING_USER_LEVEL_GET_GRADEWORLD: 'reading/user/level/GET_gradeWorld',
})

export const TEST_LEVEL = ['启蒙', '基础', '提高']
export const PAGE_META = {
  title: '定级测评',
  description: '学而思出版中心-鹦鹉爱阅读活动',
  meta: {
    keywords: '英语，少儿教育，科普，学前，学而思',
  },
}
