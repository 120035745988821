import React from 'react'
import { logReport, isWangXiaoPlatform, getParams } from 'common/service/util'

export default class initWindows {
  constructor() {
    this.init()
  }

  init = () => {
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
      [window.cicpEntryUrl] = window.location.href.split('#')
    }
    // 存储product_id
    let { pathname } = window.location
    if (pathname.indexOf('/cicp/store') !== -1) {
      let pid = ''
      if (window.location.pathname.startsWith('/cicp/store')) {
        pid = window.location.pathname.split('/')[2].replace('store', '') || ''
      }
      // let pid = window.location.pathname.split('/')[2].substring(5)
      if (pid) {
        window.sessionStorage.setItem('STORE_PRODUCT_ID', pid)
        window.CICP_STORE_ID = pid
      }
      // eslint-disable-next-line camelcase
      const { xeswx_sourceid, ocpx } = getParams()
      // eslint-disable-next-line camelcase
      if (xeswx_sourceid) {
        window.sessionStorage.setItem('STORE_SOURCE_ID', xeswx_sourceid)
      }
      if (ocpx) {
        window.sessionStorage.setItem('OCPX', encodeURIComponent(ocpx))
      }
    }
    window.GET_CICP_STORE_NEW_URL = this.getCicpStoreNewUrl
    // 全局增加异常处理 上报
    if (!React.Component.prototype.componentDidCatch) {
      React.Component.prototype.componentDidCatch = (error, info) => {
        return logReport(error.stack || '', null, null, null, info.componentStack)
      }
    }
    window.onerror = logReport
    window.isSupportedWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

    // 全局增加是否显示划线价或者折扣
    window.showLinePrice = this.getShowLinePrice();

    // 全局添加是否是学而思出版中心
    window.xesChuBan = this.getIsXesChuBan();
    // 全局添加是否是集团中台增长部
    window.jituanZT = this.getIsZT();
    // 全局添加是否是农行
    window.nongHang = this.getIsNH();
    // 全局添加是否是网校
    window.wangxiao = this.getIsWXIAO();
    // 全局添加是否是培优
    window.peiyou = this.getIsPeiYou();
  }

  getCicpStoreNewUrl = (url = '', addSource = true) => {
    const isStore = window.location.pathname.indexOf('/cicp/store') !== -1
    const isStoreUrl = url.indexOf('/cicp/store') !== -1
    if (isStore && isStoreUrl) {
      const newStore = window.location.pathname.split('/')[2]
      let temurl = url.replace(/\/cicp\/store\//g, `/cicp/${newStore}/`)
      const { xeswx_sourceid: sourceId } = getParams()
      const localId = window.sessionStorage.getItem('STORE_SOURCE_ID')
      let source = sourceId || localId
      let returnUrl = temurl
      if (source && addSource) {
        returnUrl = returnUrl.includes('?') ? `${returnUrl}&xeswx_sourceid=${source}` : `${returnUrl}?xeswx_sourceid=${source}`
      }
      return returnUrl
    }
    console.log('getCicpStoreNewUrl', url)
    return url
  }

  // 是否展示划线价
  getShowLinePrice = () => {
    if (['9568a597a35f5071', '22e5f3ea5d90ae5c'].indexOf(window.CICP_STORE_ID) > -1) {
      return false;
    }
    return true;
  }

  // 集团中台增长部
  getIsZT = () => {
    if (['7e3781408a21f8a3'].indexOf(window.CICP_STORE_ID) > -1) {
      return true;
    }
    return false;
  }

  // 网校
  getIsWXIAO = () => {
    if (['9568a597a35f5071', '22e5f3ea5d90ae5c'].indexOf(window.CICP_STORE_ID) > -1) {
      return true;
    }
    return false;
  }

  // 农行
  getIsNH = () => {
    if (['9e4552b738d2cfe6'].indexOf(window.CICP_STORE_ID) > -1) {
      return true;
    }
    return false;
  }

  // 是否是出版中心
  getIsXesChuBan = () => {
    if (!window.CICP_STORE_ID) {
      return true;
    }
    if (['e7ad710a7c98ba38', 'e4ef6de24b11360a'].indexOf(window.CICP_STORE_ID) > -1) {
      return true;
    }
    return false;
  }

  // 培优
  getIsPeiYou = () => {
    if (['35cc49e6db8bcdde', 'a0507cf0c84609a3'].indexOf(window.CICP_STORE_ID) > -1) {
      return true;
    }
    return false;
  }
}