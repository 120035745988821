import { handleActions } from 'redux-actions'
import { ACTION_TYPE } from 'consts/store/order/confirmOrder'

const {
  CONFIRM_ORDER_INFO,
} = ACTION_TYPE

const initialState = {
  orderConfirmInfo: {},
}

let reducers = {
  [CONFIRM_ORDER_INFO](state, action) {
    console.log(state)
    return {
      ...state,
      orderConfirmInfo: action.payload,
    }
  },
}
export default handleActions(reducers, initialState)
