import { getParams } from 'common/service/util'
import { apiYachLogin } from 'api/store/home/index'
import { apiCodeLogin } from 'api/store/user/auth'
import { ZHIYINLUO_APP, CHANNEL_APP_KEY_CICP } from 'consts/store/channel'
import { TOKEN_KEY, TAL_ID_KEY, USER_INFO_KEY } from 'consts/store/user'

export default class channelLogin {
  constructor() {
    this.init()
  }

  init = () => {
    const { channel, token } = getParams()
    if (!channel) return
    window.localStorage.setItem(CHANNEL_APP_KEY_CICP, channel)
    switch (channel) {
      case ZHIYINLUO_APP:
        if (token) {
          this.handleApiYachLogin(token)
        }
        break;
      default:
        const { code } = getParams()
        if (code && code !== 'unlogin') {
          this.handleApiCodeLogin(code)
        }
        if (code === 'unlogin') {
        // removeTokenReload()
        }
        break;
    }
  }

  removeTokenReload = () => {
    window.history.replaceState({}, document.title, window.location.href.split('?')[0])
    window.location.reload()
  }

  handleApiYachLogin = (yachToken) => {
    const data = {
      yach_token: yachToken,
    }
    apiYachLogin(data).then((res) => {
      if (res.data.token) {
        window.localStorage.setItem(TOKEN_KEY, res.data.token)
      } else {
        window.localStorage.setItem(ZHIYINLUO_APP, res.data.secret)
        window.localStorage.removeItem(TOKEN_KEY)
        window.localStorage.removeItem(TAL_ID_KEY)
        window.localStorage.removeItem(USER_INFO_KEY)
      }
    })
  }

  handleApiCodeLogin = async (code) => {
    console.log('用户中心登录')
    const data = {
      tal_code: code,
    }
    apiCodeLogin(data).then((res) => {
      console.log('res,', res)
      window.localStorage.setItem(TOKEN_KEY, res.data.token)
      window.history.replaceState({}, document.title, window.location.href.split('?')[0])
    }).catch((error) => {
      if (error.code === 1020301) {
        this.removeTokenReload()
      }
    })
  }
}
