import { handleActions } from 'redux-actions'
import { createApiReducers } from 'reducers/utils'
import actionTypes from 'consts/wx'

const {
  POST_WX_SIGNATURE,
  POST_WX_SIGNATURE_SESAME,
  POST_WX_SIGNATURE_MELON,
  POST_WX_SIGNATURE_PLATFORM,
} = actionTypes

const initialState = {
  requestings: {},
  errors: {},
  wxConfig: {},
}

let reducers = {
  ...createApiReducers([
    POST_WX_SIGNATURE,
    POST_WX_SIGNATURE_SESAME,
    POST_WX_SIGNATURE_MELON,
    POST_WX_SIGNATURE_PLATFORM,
  ]),
  [POST_WX_SIGNATURE](state, action) {
    return {
      ...state,
      wxConfig: action.payload.data,
      requestings: {
        ...state.requestings,
        [POST_WX_SIGNATURE]: false,
      },
      errors: {
        ...state.errors,
        [POST_WX_SIGNATURE]: null,
      },
    }
  },
  [POST_WX_SIGNATURE_SESAME](state, action) {
    return {
      ...state,
      wxConfig: action.payload.data,
      requestings: {
        ...state.requestings,
        [POST_WX_SIGNATURE_SESAME]: false,
      },
      errors: {
        ...state.errors,
        [POST_WX_SIGNATURE_SESAME]: null,
      },
    }
  },
  [POST_WX_SIGNATURE_MELON](state, action) {
    return {
      ...state,
      wxConfig: action.payload.data,
      requestings: {
        ...state.requestings,
        [POST_WX_SIGNATURE_MELON]: false,
      },
      errors: {
        ...state.errors,
        [POST_WX_SIGNATURE_MELON]: null,
      },
    }
  },
  [POST_WX_SIGNATURE_PLATFORM](state, action) {
    return {
      ...state,
      wxConfig: action.payload.data,
      requestings: {
        ...state.requestings,
        [POST_WX_SIGNATURE_PLATFORM]: false,
      },
      errors: {
        ...state.errors,
        [POST_WX_SIGNATURE_PLATFORM]: null,
      },
    }
  },
}
export default handleActions(reducers, initialState)
