import React from 'react'
import classNames from 'classnames/bind'
import { getParams } from 'common/service/util'
import styles from './style.styl'


const cx = classNames.bind(styles)
const Home = () => {
  const { token, redirect_url: redirectUrl, status = 200 } = getParams()
  if (token && redirectUrl && status === 200) {
    if (redirectUrl.indexOf('test') !== -1) {
      window.localStorage.setItem('devBoxToken', token)
    } else {
      window.localStorage.setItem('BoxToken', token)
    }
    return window.location.href = redirectUrl
  }
  if (status !== 200) return <div className={cx('wrapper')}>{status}：登录失败</div>
  return (
    <div className={cx('wrapper')}>it's works!</div>
  )
}

export default Home
