import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

const NotFoundPage = lazy(() => import('pages/system/NotFound'))
const UpdatePage = lazy(() => import('pages/system/Update'))

const SystemRoute = ({ match }) => (
  <Suspense fallback={<div>loading</div>}>
    <Switch>
      <Route path={`${match.url}/notfound`} component={NotFoundPage} />
      <Route path={`${match.url}/update`} component={UpdatePage} />
    </Switch>
  </Suspense>
)

export default SystemRoute
