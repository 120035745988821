import React from 'react'
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind'
import styles from './style.styl'
import selectPng from './select.png'
import closePng from './closeImg.png'
import wechatPng from './wechat.png'
import alipayPng from './alipay.png'

const cx = classNames.bind(styles)

let Type = {
  wxpay: 'wxpay',
  alipay: 'alipay',
  wxpayH5: 'wxpay_h5',
}

class PaySelect extends React.Component {
  constructor(props) {
    super(props)
    const type = /MicroMessenger/i.test(navigator.userAgent) ? Type.wxpay : Type.wxpayH5
    this.state = {
      list: [
        {
          id: 1,
          title: '微信支付',
          type,
          icon: wechatPng,
        },
        {
          id: 2,
          title: '支付宝支付',
          type: Type.alipay,
          icon: alipayPng,
        },
      ],
      selectIndex: 0,
      show: false,
      alternate: false,
    }
  }

  show = (index = 0) => {
    this.setState({
      show: true,
      selectIndex: index,
    })
  }


  close =() => {
    this.handleCloseClick()
  }

  handleCloseClick = (callBack) => {
    this.setState({
      alternate: true,
    })
    this.timeOut = setTimeout(() => {
      this.setState({
        show: false,
        alternate: false,
      }, callBack)
    }, 250)
  }

  handleItemClick =(e, index) => {
    e.stopPropagation()
    this.setState({
      selectIndex: index,
    })
  }

  handleSubClick = (e) => {
    e.stopPropagation()
    const { list, list1, selectIndex } = this.state
    if (selectIndex === -1) return
    this.handleCloseClick(() => {
      const { type } = this.props
      let data = list1
      if (type === Type.ReturnGoods) {
        data = list
      }
      this.handleSelect(data[selectIndex].type)
    })
  }

  render() {
    const { list, show, selectIndex, alternate } = this.state
    if (!show) {
      return null
    }
    let data = list
    let title = '选择支付方式'
    return (
      <div className={cx('root', alternate ? 'root-box-alternate' : 'root-box-normal')} onClick={this.close} >
        <div className={cx('body-box')} >
        <div className={cx('body', alternate ? 'delete-box-alternate' : 'delete-box-normal')} >
          {
            data.map((item, index) => {
              return (
                <div className={cx('item-box')} onClick={ (e) => { this.handleItemClick(e, index) }} >
                  <div className={cx('icon-box')}>
                    <img className={cx('icon')} src={item.icon} ></img>
                    <label>{item.title}</label>
                  </div>
                  {
                    selectIndex !== index && <div className={cx('select-div')} ></div>
                  }
                  {
                    selectIndex === index && <img src={selectPng} ></img>
                  }
                </div>
              )
            })
          }
          <div className={cx('line')} ></div>
          <div className={cx('sub-button-box')}>
          <div onClick={this.handleSubClick} className={cx('sub-button-disable', selectIndex !== -1 && 'sub-button')} >确认支付</div>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

let div = document.createElement('div');
document.body.appendChild(div);
const paySelect = ReactDOM.render(React.createElement(
  PaySelect,
), div)
export default paySelect
