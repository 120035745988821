import fetch from 'isomorphic-fetch'
import localforage from 'localforage'
import { HTTP_STATUS, COMMON_STATUS } from 'consts/statusCode'
import { TOKEN_KEY, TAL_ID_KEY, USER_INFO_KEY, SPREAD_ID_KEY } from 'consts/store/user'
import { genUrl, encodeParam } from 'common/service/url'
import { Toast } from 'antd-mobile';

const getParams = () => {
  let pid = ''
  try {
    if (window.location.pathname.startsWith('/cicp/store')) {
      pid = window.location.pathname.split('/')[2].replace('store', '') || ''
    }
  } catch (e) {}
  return { pid }
}

const METHOD_GET = 'GET'
const METHOD_POST = 'POST'

const commonFetch = (api, config = {}) => {
  return fetch(api, config)
}

const storageDealFn = (api, storageCallback) => {
  localforage.getItem(api).then(value => {
    let res = value
    try {
      res = JSON.parse(value)
    } catch (e) {}
    storageCallback(res)
  })
}

export async function fetchJson(api, config, storageCallback) {
  storageCallback && storageDealFn(api, storageCallback)
  const COMMON_ERROR_MESSAGE = '出错了，请稍后再试...'
  /* eslint-disable no-throw-literal */
  try {
    let response = await commonFetch(api, config)
    if (response.status === HTTP_STATUS.AUTHENTICATE) {
      throw { status: COMMON_STATUS.AUTH_FAILED, data: null, message: '认证错误' }
    } else if (response.status === HTTP_STATUS.CLIENT_ERROR) {
      throw { status: COMMON_STATUS.CLIENT_ERROR, data: null, message: COMMON_ERROR_MESSAGE }
    } else if (response.status === HTTP_STATUS.SERVER_ERROR) {
      throw { status: COMMON_STATUS.EXCEPTION, data: null, message: COMMON_ERROR_MESSAGE }
    } else if (response.status !== 200) {
      throw { status: response.status, data: null, message: COMMON_ERROR_MESSAGE }
    }
    response = await response.json()
    let res = response || { status: COMMON_STATUS.EXCEPTION, message: COMMON_ERROR_MESSAGE }
    const {
      status,
      message,
      data,
      code,
    } = res
    res = Object.assign({}, {
      code,
      status,
      message,
      data,
      responseTime: res.response_time,
    })
    // 用户未登录
    if (res.status === 403) {
      window.localStorage.removeItem(TOKEN_KEY)
      window.localStorage.removeItem(TAL_ID_KEY)
      window.localStorage.removeItem(USER_INFO_KEY)
      try {
        throw {
          status: res.status,
          data: res.data,
          message: res.message,
          serverTime: res.time,
          responseTime: res.response_time,
          code: res.code,
        }
      } catch (err) {
        throw err
      } finally {
        console.log(res.status)
        const curUrl = encodeURIComponent(window.location.href)
        const url = window.GET_CICP_STORE_NEW_URL('/cicp/store/user/login', false)
        window.location.replace(`${window.location.origin + url}?jumpurl=${curUrl}`)
      }
    }
    // 400 弹出toast提示
    if (res.status === 400) {
      throw {
        status: res.status,
        data: res.data,
        message: res.message,
        serverTime: res.time,
        responseTime: res.response_time,
        code: res.code,
      }
    }
    if (res.status !== 200) {
      throw {
        status: res.status,
        data: res.data,
        message: res.message,
        serverTime: res.time,
        responseTime: res.response_time,
      }
    }
    storageCallback && localforage.setItem(api, JSON.stringify(res))
    return res
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(`错误类型：${err.status}，错误信息：${err.message}`)
    }
    if (err.name) {
      Toast.info(COMMON_ERROR_MESSAGE, 2.5, null, false)
    } else if (err.message) {
      Toast.info(err.message, 2.5, null, false)
    }
    throw err
  }
}

export const fetchApi = (url, params, options, storageCallback) => {
  let cfg = {
    method: METHOD_GET,
    credentials: 'same-origin',
    headers: {
      token: window.localStorage.getItem(TOKEN_KEY),
    },
  };
  let thisParams
  if (params && params.product_id && params.product_id.toLowerCase() !== 'undefined') {
    thisParams = { ...params, client: 'web', time: Date.now() }
  } else {
    const { pid } = getParams()
    if (params && params.product_id && params.product_id.toLowerCase() === 'undefined') {
      delete params.product_id
    }
    thisParams = { ...params, client: 'web', time: Date.now(), product_id: pid }
  }
  const _spreadid = window.sessionStorage.getItem(SPREAD_ID_KEY)
  if (_spreadid) {
    thisParams.monitor_page_id = _spreadid
  }
  let fetchUrl = genUrl(url, thisParams)
  return fetchJson(fetchUrl, Object.assign({}, cfg, options), storageCallback)
}

export const postApi = (url, data, options, storageCallback) => {
  let thisParams
  if (data && data.product_id && data.product_id.toLowerCase() !== 'undefined') {
    thisParams = { ...data, client: 'web', time: Date.now() }
  } else {
    const { pid } = getParams()
    if (data && data.product_id && data.product_id.toLowerCase() === 'undefined') {
      delete data.product_id
    }
    thisParams = { ...data, client: 'web', time: Date.now(), product_id: pid }
  }
  const _spreadid = window.sessionStorage.getItem(SPREAD_ID_KEY)
  if (_spreadid) {
    thisParams.monitor_page_id = _spreadid
  }
  let cfg = {
    method: METHOD_POST,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      token: window.localStorage.getItem(TOKEN_KEY),
    },
    body: JSON.stringify(thisParams),
  }
  return fetchJson(url, Object.assign({}, cfg, options), storageCallback)
}


export async function fetchFile(api, config) {
  /* eslint-disable no-throw-literal */
  try {
    let res = await commonFetch(api, config)
    console.log('图片上传oss成功', res)
    return res
  } catch (err) {
    console.error(`错误类型：${err.status}，错误信息：${err.message}`)
    throw err
  }
}

export const postFormData = (url, data, options) => {
  let cfg = {
    method: METHOD_POST,
    body: data,
  }
  return fetchFile(url, Object.assign({}, cfg, options))
}
