import * as USER from './consts/store/user'

export default class Zc {
  static dynamic() {
    window.zc && window.zc('dynamic_ready')
    setTimeout(() => {
      window.zc && window.zc('dynamic_ready')
    }, 500)
  }

  static setUserInfo() {
    let userInfo = {}
    try {
      userInfo = JSON.parse(localStorage.getItem(USER.USER_INFO_KEY)) || {}
    } catch (e) {
      // empty
    }
    window.zc && window.zc('config', {
      partnerid: localStorage.getItem(USER.TAL_ID_KEY),
      uname: userInfo.nickname,
      tel: userInfo.phone,
    })
  }

  // 设置商品信息
  static setGoodInfo(goodInfo = {}) {
    console.log('kf goodInfo', goodInfo)
    Zc.setUserInfo()
    window.zc('config', {
      card_title: goodInfo.card_title,
      card_url: window.location.href,
      card_desc: goodInfo.card_desc,
      card_note: goodInfo.card_note,
      card_picture: goodInfo.card_picture,
    })
  }

  // 设置订单信息
  static setOrderInfo(orderInfo = {}) {
    Zc.setUserInfo()
    console.log('kf orderInfo', orderInfo)
    window.zc('config', orderInfo)
    Zc.dynamic()
  }
}
