import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Goods from './goods'
import User from './user'
import Group from './group'

const ActivityRoute = ({ match }) => (
  <BrowserRouter>
    <Switch>
      <Route path={`${match.url}/user`} component={User} />
      <Route path={`${match.url}/goods`} component={Goods} />
      <Route path={`${match.url}/group`} component={Group} />
      <Redirect to={`${match.url}/goods/home`}/>
    </Switch>
  </BrowserRouter>
)

export default ActivityRoute
