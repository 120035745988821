import { handleActions } from 'redux-actions'
import { createApiReducers } from 'reducers/utils'
import actionTypes from 'consts/reading/user/level'

const {
  READING_USER_LEVEL_GET_GRADEAGE,
  READING_USER_LEVEL_GET_GRADEWORLD,
} = actionTypes

const initialState = {
  requestings: {},
  errors: {},
  freecourseData: null,
}

let reducers = {
  ...createApiReducers([
    READING_USER_LEVEL_GET_GRADEAGE,
    READING_USER_LEVEL_GET_GRADEWORLD,
  ]),
  [READING_USER_LEVEL_GET_GRADEAGE](state, action) {
    return {
      ...state,
      gradeAgeData: action.payload.data,
      requestings: {
        ...state.requestings,
        [READING_USER_LEVEL_GET_GRADEAGE]: false,
      },
      errors: {
        ...state.errors,
        [READING_USER_LEVEL_GET_GRADEAGE]: null,
      },
    }
  },
  [READING_USER_LEVEL_GET_GRADEWORLD](state, action) {
    return {
      ...state,
      gradeWorldData: action.payload.data,
      requestings: {
        ...state.requestings,
        [READING_USER_LEVEL_GET_GRADEWORLD]: false,
      },
      errors: {
        ...state.errors,
        [READING_USER_LEVEL_GET_GRADEWORLD]: null,
      },
    }
  },
}
export default handleActions(reducers, initialState)
